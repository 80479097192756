import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  InputNumber,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useProduct } from "./store";
import TextArea from "antd/lib/input/TextArea";
import { useMutation, useQuery } from "react-query";

import { createProduct, editProduct, getBrand, getCategory } from "../../api";
import { useEffect, useState } from "react";
import handleDataMutation from "../../helper/dataMutation";
import { inputNumberFormatter } from "../../helper/inputNumberFormatter";
const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    name,
    description,
    // categoryID,
    // subCategoryID,
    // brandID,
    priority,
    // limit,
    // setLimit,
    setPriority,
    setName,
    setId,
    setDescription,
    setCategoryID,
    setSubCategoryID,
    setBrandID,
    selectedCategory,
    selectedSubCategory,
    selectedBrand,
    setSelectedCategor,
    setSelectedSubCategory,
    setSelectedBrand,
  } = useProduct();
  const [form] = Form.useForm();

  const [subCatSearch, setSubCatSearch] = useState("");
  const [catSearch, setCatSearch] = useState("");
  const [brandSearch, setBrandSearch] = useState("");

  const resetState = () => {
    setName(null);
    setId(null);
    setDescription(null);
    setSelectedCategor(null);
    setSelectedSubCategory(null);
    setSelectedBrand(null);
    setPriority(null);
    //setLimit(null);
    // setCategoryID(null);
    // setSubCategoryID(null);
    // setBrandID(null);
  };

  const {
    isLoading: catLoading,
    data: catList,
    refetch: catRefetch,
  } = useQuery(["category", catSearch, , 1000], getCategory, {
    enabled: !!catSearch,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: subCatLoading,
    data: subCatList,
    refetch: subCatRefetch,
  } = useQuery(
    ["subCategory", subCatSearch, , 1000, selectedCategory],
    getCategory,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!selectedCategory,
    }
  );

  const {
    isLoading: brandLoading,
    data: brandData,
    refetch: brandRefetch,
  } = useQuery(["brand", brandSearch, 1], getBrand, {
    enabled: !!brandSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (selectedCategory) {
      subCatRefetch();
    }
  }, [selectedCategory]);

  const { mutate, isLoading } = useMutation(createProduct, {
    onSuccess: (data) => {
      handleDataMutation({
        mutatedData: data,
        queryClientKey: "products",
        setterList: [() => setIsCreateModal(false)],
        resetFunc: resetState,
      });
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editProduct,
    {
      onSuccess: (data) => {
        handleDataMutation({
          mutatedData: data,
          queryClientKey: "products",
          setterList: [() => setIsCreateModal(false)],
          resetFunc: resetState,
        });
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    let _data = {
      name: values.name,
      priority: values.priority,
      // limit: values.limit,
      categoryID: selectedSubCategory,
      // subCategoryID: localSubCategoryID,
      brandID: selectedBrand,
      description: values.description,
    };
    if (id) {
      _data.id = id;
      editMutate(_data);
    } else {
      mutate(_data);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Product</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={600}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{
          remember: true,
          name,
          description,
          categoryID: selectedCategory,
          subCategoryID: selectedSubCategory,
          brandID: selectedBrand,
          priority,
          // limit
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={16}>
            <Form.Item
              label="Product Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="product name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          {/* new input field */}
          {/* <Col span={8}>
            <Form.Item
              label="Limit"
              name="limit"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                formatter={(value) => inputNumberFormatter(value)}
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item
              label="Category"
              name="categoryID"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={false}
                onSearch={setCatSearch}
                onChange={(val) => {
                  form.setFieldsValue({
                    subCategoryID: null,
                  });
                  setSelectedCategor(val);

                  setSelectedSubCategory(null);
                  setSubCatSearch(null);
                }}
                placeholder="Choose a Category"
                loading={catLoading}
                onClick={() => catRefetch()}
              >
                {catList && catList.success
                  ? catList?.data?.categories?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sub Category"
              name="subCategoryID"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={false}
                onSearch={setSubCatSearch}
                onChange={(val) => setSelectedSubCategory(val)}
                placeholder="Choose a Sub Category"
                loading={subCatLoading}
                onClick={() => subCatRefetch()}
              >
                {subCatList && subCatList.success
                  ? subCatList?.data?.categories?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Brand"
              name="brandID"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={false}
                onSearch={setBrandSearch}
                onChange={(val) => setSelectedBrand(val)}
                placeholder="Choose a Brand"
                loading={brandLoading}
                onClick={() => brandRefetch()}
              >
                {brandData && brandData.success
                  ? brandData?.data?.brands?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <TextArea rows={2} placeholder={"product Description"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
